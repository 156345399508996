<template>
  <div class="d-inline endereco">
    <span
      v-for="(parte, idx) in partes"
      :key="idx"
      class="d-inline-block"
    >
      <a
        v-if="linkValido(parte)"
        :href="enderecoCompleto(parte)"
        rel="noopener noreferrer"
        target="_blank"
      >{{ parte + ' ' }}</a>
      <span v-else>{{ parte + ' ' }}</span>
    </span>
  </div>
</template>

<script>
export default {
  name: "LinkEndereco",
  props: {
    endereco: {
      type: String,
      default: ''
    }
  },
  computed: {
    partes() {
      if (this.endereco.indexOf("http")!== -1 || this.endereco.indexOf("www")!== -1) {
        return this.endereco.split(/[,|\s+]/) || []
      }
      return [this.endereco]
    }
  },
  methods: {
    linkValido(parte) {
      return (parte?.substring(0,4) == "http" || parte?.substring(0,3) == "www")
    },
    enderecoCompleto(parte) {
      if(parte?.substring(0,3) == "www") {
        return "https://" + parte
      }
      return parte
    }
  }
}
</script>

<style lang="scss" scoped>
span {
  white-space: pre;
}
.endereco {
  margin-left: 0.35rem;
}
</style>