<template>
  <section>
    <uspCartao
      titulo="Filtros de Consulta"
    >
      <template #corpo>
        <form
          novalidate
          @submit.prevent=""
          @keydown.enter="consultar(1)"
        >
          <h6>Localidade</h6>
          <div class="form-row">
            <div class="form-group col-12 col-lg-6 col-xl-4">
              <label for="busca-campi">Campus:</label>
              <select
                id="busca-campi"
                v-model="codcam"
                class="form-control"
                @change="recarregarUnidades"
              >
                <option value="">
                  Todos
                </option>
                <option
                  v-for="campus in listaCampus"
                  :key="campus.codcam"
                  :value="campus.codcam"
                >
                  {{ campus.nomcam }}
                </option>
              </select>
            </div>
            <div class="form-group col">
              <label for="busca-unidade">Unidade/Órgão:</label>
              <select
                id="busca-unidade"
                v-model="codunddsp"
                class="form-control"
              >
                <option value="">
                  Todos
                </option>
                <option
                  v-for="unidade in listaUnidades"
                  :key="unidade.codunddsp"
                  :value="unidade.codunddsp"
                >
                  {{ unidade.codunddsp }} - {{ unidade.sglunddsp }} - {{ unidade.nomunddsp }}
                </option>
              </select>
            </div>
          </div>
          <h6>Objeto</h6>
          <div class="form-row">
            <div class="form-group col-12 col-lg-6 col-xl-4">
              <label for="busca-processo">Processo <small>(SEI ou Proteos)</small>:
                <button class="ajuda">
                  <em class="fas fa-question-circle" />
                  <p class="ajudatexto">
                    Digite o número formatado completo. <br> Ex: 00.0.00000.00.0 (Proteos Físico) <br> ou 000.00000000/0000-00 (SEI)
                  </p>
                </button>
              </label>
              <input
                id="busca-processo"
                v-model="numpro"
                v-maska
                :data-maska="mascara"
                class="form-control"
              >
            </div>
            <div class="form-group col">
              <label for="busca-objeto">Nome do objeto:</label>
              <input
                id="busca-objeto"
                v-model="objcpr"
                class="form-control"
              >
            </div>
          </div>

          <div class="form-row align-items-end">
            <div class="form-group col-12 col-lg-3 col-xl-2">
              <h6>Tipo</h6>
              <label for="busca-lei">Lei:</label>
              <select
                id="busca-lei"
                v-model="numleimdldsp"
                class="form-control"
                @change="recarregarModalidades"
              >
                <option value="">
                  Todas
                </option>
                <option
                  v-for="lei in leis"
                  :key="lei.numleimdldsp"
                  :value="lei.numleimdldsp"
                >
                  {{ lei.numleimdldsp }} 
                </option>
              </select>
            </div>
            <div class="form-group col-12 col-lg-6 col-xl-4">
              <label for="busca-modalidade">Modalidade / Instrumento Auxiliar:</label>
              <select
                id="busca-modalidade"
                v-model="codmdldsp"
                class="form-control"
              >
                <option value="">
                  Todos
                </option>
                <option
                  v-for="modalidade in modalidades"
                  :key="modalidade.codmdldsp"
                  :value="modalidade.codmdldsp"
                >
                  {{ modalidade.nommdldsp }} - {{ modalidade.cplnommdldsp }}
                </option>
              </select>
            </div>
            <div class="form-group col-12 col-lg-3 col-xl-2">
              <label for="busca-situacao">Situação:</label>
              <select
                id="busca-situacao"
                v-model="codsitcpr"
                class="form-control"
              >
                <option
                  value=""
                >
                  Todas
                </option>
                <option
                  v-for="situacao in situacoes"
                  :key="situacao.codsitcpr"
                  :value="situacao.codsitcpr"
                >
                  {{ situacao.sitcpr }}
                </option>
              </select>
            </div>
            <div class="form-group col-12 col-md-6 col-xl-2">
              <h6>Período</h6> 
              <label for="busca-periodo-inicio">Início:</label>
              <input
                id="busca-periodo-inicio"
                v-model="dtaini"
                class="form-control"
                :class="{ 'erro-data' : maisDeUmAno || periodo < 0}"
                type="date"
              >
            </div>
            <div class="form-group col-12 col-md-6 col-xl-2">
              <label for="busca-periodo-inicio">Fim <small>(período máximo de 12 meses)</small>:</label>
              <input
                id="busca-periodo-fim"
                v-model="dtafim"
                class="form-control"
                :class="{ 'erro-data' : maisDeUmAno || periodo < 0 }"
                type="date"
              >
            </div>
          </div>
          
          <div class="grupo-botoes">
            <div v-if="maisDeUmAno || periodo < 0">
              <uspAlerta
                variante="perigo"
                class="mb-md-0"
              >
                <span v-if="periodo < 0">Período inválido: data final anterior a inicial</span>
                <span v-if="maisDeUmAno">Período inválido: ultrapassou 12 meses</span>
              </uspAlerta>
            </div>
            <div ref="scroll">
              <uspBotao
                type="submit"
                icone="fas fa-search"
                texto="Consultar"
                @clicar="consultar(1)"
              />
              <uspBotao
                variante="perigo"
                icone="fas fa-times"
                texto="Limpar"
                @clicar="limpar(); recarregarModalidades(); recarregarUnidades()"
              />
            </div>
          </div>
        </form>
      </template>
    </uspCartao>

    <ListaLicitacoes
      :lista-licitacoes="listaLicitacoes"
      :carregando="carregando"
      :paginas="paginas"
      :total-resultados="totalResultados"
      :pagina-atual="parseInt(paginaAtual)"
      @irPara="irPara"
    />
  </section>
</template>

<script>
import ListaLicitacoes from "../components/ListaLicitacoes.vue"
import LicitacoesServico from "../dominio/LicitacoesServico.js"

const api = LicitacoesServico.build({});

export default {
  name: 'LicitacoesBusca',
  components: {
    ListaLicitacoes
  },
  data() {
    return {
      acordeao: false,
      paginaAtual: 1,
      paginas: 1,
      totalResultados: 0,
      
      carregando: false,
      
      numpro: '',
      codmdldsp: '',
      codcam: '',
      codunddsp: '',
      objcpr: '',
      dtaini: '',
      dtafim: '',
      codsitcpr: '',
      numleimdldsp: '',
      
      situacoes: [],
      modalidades: [],
      leis: [],
      listaCampus: [], 
      listaUnidades: [],
      listaLicitacoes: [],
      
      erroBusca: '',
      erroEditais: '',
      
      dadosConsulta: {},
    }
  },
  computed: {
    periodo() {
      return (new Date(this.dtafim.replace(/-/g,'/')).getTime() - new Date(this.dtaini.replace(/-/g,'/')).getTime()) / (1000 * 60 * 60 * 24)
    },
    maisDeUmAno() {
      return this.periodo > 366
    },
    mascara() {
      if (this.numproSemPontos.length <= 11) {
        if(this.numproSemPontos[2]== 9) {
          return "##.#.#######.#####" //proteos digital
        }
        else {
          return "##.#.#####.##.#####" //proetos fisico
        }
      }
      return "###.########/####-##" //sei
    },
    numproSemPontos() {
      return this.numpro.toString().split('.').join("")
    }
  },
  created() {
    Object.entries(this.$route.query)
      .forEach(([k,v]) => this[k] = v);
    api.listarCampus().then((resp) => {
      this.listaCampus = resp.items;
    })
    .catch((erro) => {
      this.erroBusca = erro?.mensagem ?? erro;
    })
    api.listarUnidades().then((resp) => {
      this.listaUnidades = resp.items;
    })
    .catch((erro) => {
      this.erroBusca = erro?.mensagem ?? erro;
    })
    this.consultar(this.paginaAtual)
    api.listarLeis().then((resp) => {
      this.leis = resp.items
    })
    api.listarModalidades(this.numleimdldsp).then((resp) => {
      this.modalidades = resp.items
    })
    .catch((erro) => this.erroBusca = erro?.mensagem ?? erro)
    api.listarSituacoes().then((resp) => {
      this.situacoes = resp.items
    })
    .catch((erro) => this.erroBusca = erro?.mensagem ?? erro)
  },
  methods: {
    limpar() {
      this.codcam = ''
      this.numpro = ''
      this.objcpr = ''
      this.codmdldsp = ''
      this.dtaini = ''
      this.dtafim = ''
      this.codsitcpr = ''
      this.numleimdldsp = ''
      this.$router.replace({ query: {} });
    },
    buscarUnidade() {
      this.mostrarLista = true
    },
    recarregarUnidades() {
      this.codunddsp = ''
      api.listarUnidades(this.codcam).then((resp) => {
        this.listaUnidades = resp.items;
      })
      .catch((erro) => {
        this.erroBusca = erro?.mensagem ?? erro;
      })
    },
    recarregarModalidades() {
      this.codmdldsp = ''
      api.listarModalidades(this.numleimdldsp).then((resp) => {
        this.modalidades = resp.items
      })
      .catch((erro) => this.erroBusca = erro?.mensagem ?? erro)
    },
    async consultar(paginaAtual) {
      if (this.maisDeUmAno || this.periodo < 0) {
        return
      }
      this.carregando = true
      this.dadosConsulta = {
        codcam: this.codcam,
        codunddsp: this.codunddsp,
        objcpr: this.objcpr,
        numpro: this.numpro,
        codmdldsp: this.codmdldsp,
        codsitcpr: this.codsitcpr,
        dtaalt: this.dtaalt(),
        numleimdldsp: this.numleimdldsp
      }
      this.paginaAtual = paginaAtual
      this.listaLicitacoes = []
      await this.$recaptchaLoaded()
      try {
        const token = await this.$recaptcha('licitacoes')
        const query = this.buildQuery();
        if(Object.keys(query).length > 0){
          this.$router.replace({ query })
        }
        api.listarLicitacoes(this.dadosConsulta, paginaAtual, token).then((resp) => {
          this.listaLicitacoes = resp.items;
          this.paginas = resp.total_pages;
          this.totalResultados = resp.total_results;
        })
        .catch((erro) => {
          this.erroEditais = erro?.mensagem ?? erro;
        }).then(() => {
          this.carregando = false
          if (this.$refs.scroll && Object.keys(this.$route.query).length > 0) {
            window.scrollTo({ top: this.$refs.scroll.getBoundingClientRect().top + window.scrollY, behavior: 'smooth' });
          }
        })
      } catch (error) {
        console.error(error)
      }
    },
    buildQuery(){
      const self = this;
      const query = Object.fromEntries(
                        Object.entries(self.dadosConsulta)
                              .filter(([k, v]) => !!v && k !== 'dtaalt')
                    );
      if(self.paginaAtual > 1){
        query.paginaAtual = self.paginaAtual;
      }
      if(self.dtaini){
        query.dtaini = self.dtaini
      }
      if(self.dtafim){
        query.dtafim = self.dtafim
      }
      return query;
    },
    irPara(page) {
      this.paginaAtual = page
      this.consultar(this.paginaAtual)
    },
    converteData(dta, tipo) {
      let partes = dta.split('-')
      let ano = parseInt(partes[0], 10)
      let mes = parseInt(partes[1], 10) - 1
      let dia = parseInt(partes[2], 10)

      if (tipo==='fim') {
        return (new Date(ano, mes, dia, 23, 59, 59)).getTime()
      }
      return (new Date(ano, mes, dia, 0, 0, 0)).getTime()
    },
    dtaalt() {
      if(!this.dtaini && !this.dtafim) {
        return ''
      }
      else if(this.dtaini && !this.dtafim) {
        this.dtafim = new Date().toISOString().split('T')[0]
      }
      else if (!this.dtaini && this.dtafim) {
        let partes = this.dtafim.split('-')
        this.dtaini = (partes[0] - 1) + "-" + partes[1] + "-" + partes[2]
      }
      return "%5B0" + this.converteData(this.dtaini, 'inicio') + " TO 0" + this.converteData(this.dtafim, 'fim') + "%5D"
    }
  }
}
</script>

<style lang="scss" scoped>
.erro-data {
  border-color: #FF5555;
  box-shadow: 0 1px 3px 0 #FF5555;
}

.grupo-botoes {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  *:not(:last-child) {
    margin-right: 0.5rem;
  }
}

.lista-unidades {
  background-color: white;
  width: 99.5%;
  position: absolute;
  z-index: 1;
  list-style-type: none;
  padding: 0;
  border-radius: 1rem;
  border: 1px solid lightblue;
  li:not(:first-child) {
    border-top: 0.1rem solid lightblue;
  }
  li:first-child {
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
  }
  li:last-child {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  li {
    padding: 0.5rem;
  }
  li:hover {
    background-color: lightblue;
  }
}


.ajuda {
    position: relative;
    cursor: pointer;
    padding: 0;
    border: 0;
    background: 0 0;
    .ajudatexto {
      position: absolute;
      visibility: hidden;
      text-wrap: nowrap;
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: .75em;
      z-index: 1;
      opacity: 0;
      transition: opacity 1s;
      bottom: 1rem;
      max-width: 80vw;
      overflow-x: auto;
    }
  }
  .ajuda:hover .ajudatexto, .ajuda:focus .ajudatexto {
    visibility: visible;
    opacity: 1;
  } 

@media (max-width: 770px) {
  .ajudatexto {
    position: absolute;
    right: -30vw;
  }
  .grupo-botoes {
    flex-direction: column;
  }
}

</style>