<template>
  <div>
    <uspCartao
      titulo="Listagem de Contratações"
      :por-pagina="10"
      class="licitacoes"
    >
      <template #corpo>
        <div
          v-if="carregando"
          class="carregando-lista"
        >
          <uspSpinner
            style="width: 10rem; height: 10rem; border-width: 0.8rem;"
          />
        </div>
        <section v-if="!carregando">
          <p class="ml-2">
            <small 
              id="filtro-lista-help"
              class="form-text text-muted"
            >
              Página {{ paginaAtual }} - Mostrando {{ listaLicitacoes.length }} de {{ totalResultados }} resultados encontrados
            </small>
          </p>
          <div 
            v-if="listaLicitacoes.length === 0"
            class="d-flex justify-content-center align-items-center flex-column mt-5"
          >
            <img 
              src="/imgs/usp-tarefas.svg"
              alt="Ilustração de uma pilha de tarefas concluídas"
            >
            <p class="font-weight-bold text-center mt-3">
              &nbsp; Não há contratações com os filtros selecionados.
            </p>
          </div>
          <div class="list-group">
            <section
              v-for="conteudo in listaLicitacoes"
              :key="conteudo.codpcddsp"
              class="list-group-item"
            >
              <div>
                <strong>Contratação nº {{ conteudo.numcpr }}/{{ conteudo.anocpr }}</strong> <span class="mr-2">- {{ conteudo.nomunddsp }} ({{ conteudo.sglunddsp }})</span>
                <span>
                  <strong>Campus:</strong> {{ conteudo.nomcam }}
                </span>
              </div>
              <div class="row-compra">
                <span>
                  <strong>Compra:</strong>
                  {{ conteudo.codpcddsp }}
                </span>
                <span>
                  <strong>Processo:</strong>
                  {{ conteudo.numpro }}
                </span>
                <span>
                  <strong>Modalidade:</strong>
                  {{ conteudo.nommdldsp }} <span v-if="conteudo.cplnommdldsp">- {{ conteudo.cplnommdldsp }}</span>
                </span>
              </div>
              <span v-if="conteudo.codoftcpr!=null">
                <strong>Oferta de Compra BEC:</strong> {{ conteudo.codoftcpr }}
              </span>
              <span>
                <strong>Objeto:</strong> {{ conteudo.objcpr }}<em
                  v-if="!conteudo.objcpr"
                  class="text-muted"
                >Não há.</em>
              </span>
              <span class="campo-obscpr">
                <strong>Observações:</strong> {{ conteudo.obscpr }}<em
                  v-if="!conteudo.obscpr"
                  class="text-muted"
                >Não há.</em>
              </span>
              <span>
                <strong>Situação:</strong> {{ conteudo.sitcpr }}
              </span>
              <span>
                <em class="small">Atualizado em: {{ conteudo.dtaalt }}</em>
              </span>
              <router-link
                :to="{name:'Contratacoes:Detalhamento', params: {codpcddsp: conteudo.codpcddsp}, query: $route.query }"
                class="botao-detalhes"
              >
                <uspBotao
                  texto="Ver Detalhes"
                  icone="fa fa-folder-open mr-2"
                />
              </router-link>
            </section>
          </div>
          <nav class="d-flex justify-content-center">
            <uspPaginacao
              :num-paginas="numPaginas"
              :paginas="paginas"
              :inicio="paginaAtual"
              @paginar="irPara"
            />
          </nav>
        </section>
      </template>
    </uspCartao>
  </div>
</template>

<script>
export default {
  name: 'ListaLicitacoes',
  props: {
    listaLicitacoes: {
      type: Array,
      required: false,
      default: null
    },
    carregando: {
      type: Boolean,
      required: true
    },
    paginas: {
      type: Number,
      required: true
    },
    totalResultados: {
      type: Number,
      required: true
    },
    paginaAtual: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      listaFiltradas: [],
      filtro: '',
      numPaginas: 8,
    }
  },
  mounted() {
    this.contarNumPaginas();
    window.addEventListener('resize', this.contarNumPaginas);
  },
  unmounted() {
    window.removeEventListener('resize', this.contarNumPaginas);
  },
  methods: {
    irPara(index) {
      this.$emit('irPara', index)
    },
    contarNumPaginas() {
      let width = window.innerWidth;
      if(width <= 540){
        this.numPaginas = 3;
      } else if(width > 540 && width <= 768) {
        this.numPaginas = 5;
      } else {
        this.numPaginas = 8;
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/componentes/estilos/_temas.scss';

@media screen and (min-width: 1200px) {
  .licitacoes {
    .list-group-item {
      position: relative;
      padding-right: 11rem;
    }
    .botao-detalhes {
      position: absolute;
      right: 1rem;
      top: 40%;
    }
  }
}

.licitacoes {
  .list-group {
    border-radius: 0.8rem;
    .list-group-item {
      border-width: 50%;
      display: flex;
      flex-direction: column;
      *:not(:last-child) {
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
      }
    }
    span strong {
      font-weight: 500;
    }
  }
}

@each $tema in $temas {
  $elemento: map-get($tema, "elemento");
  #{$elemento} .licitacoes {
    .list-group {

      .list-group-item{
        background: map-get($tema, "usp-subelemento-cor-de-fundo");
        color: map-get($tema, "usp-elemento-cor-do-texto");
        &.active{
          background: map-get($tema, "usp-subelemento-cor-de-fundo-ativo");
        }
        &.list-group-item:hover{
          background: map-get($tema, "usp-subelemento-cor-de-fundo-desabilitado");
        }
      }
      span strong {
        color: map-get($tema, "usp-subelemento-cor-do-link");
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  .row-compra {
    display: flex;
    flex-direction: column;
  }
}

.licitacoes .row-compra {
  span {
    margin-right: 0.5rem;
  }
}

@media (max-width: 770px) {
.botao-detalhes {
  display: flex;
  width: 100%;
  flex-direction: column;
}
}

.botao-detalhes {
  align-self: center;
  margin: 0.3rem 0;
}


.carregando-lista {
  height: 90vh;
  display: flex;
  margin-top: 6rem;
  justify-content: center;
}
.campo-obscpr {
  white-space: pre-wrap;
}
</style>