<template>
  <section>
    <uspCartao 
      :titulo="titulo" 
      :fechavel="false"
      imprimir
      :formatar-impressao="formatarImpressao"
    >
      <template
        v-if="carregando"
        #corpo
      >
        <uspSkeleton>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
        </uspSkeleton>
      </template>
      <template 
        v-else-if="erro"
        #corpo
      >
        <uspAlerta variante="perigo">
          Não encontradas compras concluídas com o bem #{{ codbem }}.
        </uspAlerta>
      </template>
      <template 
        v-else
        #corpo
      >
        <section class="dados d-flex flex-column mb-1">
          <div>
            <strong>Descrição:</strong> 
            <p 
              v-if="descricao"
              class="descricao pl-2"
            >
              {{ descricao }}
            </p> 
            <em 
              v-else
              class="text-muted"
            >
              Não há.
            </em>
          </div>
          <div>
            <strong>Quantidade de Fornecedores:</strong>  {{ numFornecedoresUnicos }}
          </div>
          <div>
            <strong>Período:</strong> {{ minData }} à {{ maxData }}
          </div>
          <strong>Valores Unitários:</strong>
          <div class="valores pl-3">
            <div>
              <p>Menor:</p> {{ menor }}
            </div>
            <div>
              <p>Média:</p> {{ media }}
            </div>
            <div>
              <p>Mediana:</p> {{ mediana }}
            </div>
            <div>
              <p>Maior:</p> {{ maior }}
            </div>
          </div>
        </section>
        <section class="listagem">
          <uspTabela
            :campos="campos"
            :dados="itens"
            :acoes="acoes"
            :responsivo="true"
            :por-pagina="itens.length"
            @selecionar="tratarAcoes"
          >
            <template #naoCalcular="{ data }">
              <span class="form-check">
                <input
                  :id="`${data.codpcddsp}-${data.numord}`"
                  v-model="valoresSelecionados"
                  class="form-check-input"
                  type="checkbox"
                  :value="{
                    valor: data.prcuntemp,
                    id: `${data.codpcddsp}-${data.numord}`
                  }"
                  @change="calcularEstatisticas()"
                >
                <label
                  class="form-check-label"
                  :for="`${data.codpcddsp}-${data.numord}`"
                >Não incluir</label>
              </span>
            </template>
          </uspTabela>
        </section>
      </template>
    </uspCartao>
  </section>
</template>

<script>
import { stringParaDate, formataData, formataDecimal } from "@/utils/Formatador.js";
import LicitacoesServico from "../dominio/LicitacoesServico.js"

const api = LicitacoesServico.build({});

  export default {
    name: 'LicitacoesBem',
    data() {
      return {
        codbem: this.$route.params.codbem,
        itens: '',
        erro: '',
        descricao: '',
        valoresSelecionados: [],
        soma: 0,
        menor: 0,
        media: 0,
        mediana: 0, 
        maior: 0,
        datas: [],
        carregando: false,
        campos: [
        {
          campo: 'acoes',
          nome: 'Ações'
        },
        {
          campo: 'codpcddsp',
          nome: 'Compra',
        },
        {
          campo: 'dscbem',
          nome: 'Complemento',
          formato: (valor) => {
            return valor?.split("|")[1] || '-'
          }
        },
        {
          campo: 'dtaefe',
          nome: 'Data',
          formato: (valor) => {
            return valor || '-'
          }
        },
        {
          campo: 'nomfrn',
          nome: 'Fornecedor',
          ordenavel: true,
          formato: (valor) => {
            return valor || '-'
          }
        },
        {
          campo: 'qtdtotefe',
          nome: 'Quantidade',
          ordenavel: true,
          numerico: true,
          alinhamento: 'direita',
          formato: (valor) => {
            const indice = valor.indexOf('.') + 4
            return parseFloat(valor.substr(0, indice)).toLocaleString('pt-BR', {minimumFractionDigits: 0}) + " " + valor.substr(indice)
          }
        },
        {
          campo: 'prcuntemp',
          nome: 'Valor Unitário',
          ordenavel: true,
          numerico: true,
          alinhamento: 'direita',
          formato: (valor) => {
            return valor ? parseFloat(valor).toLocaleString('pt-BR', {minimumFractionDigits: 2}) : '-'
          }
        },
        {
          campo: 'naoCalcular',
          nome: 'Para o cálculo',
          slot: 'naoCalcular'
        }
      ],
      acoes: [
        {
          titulo: "Ver compra",
          icone: "fas fa-external-link-alt",
          tamanho: "pq",
          variante: "primario",
          condicao: (dados) => {
            return dados.codpcddsp !== null
          }
        },
      ]
      }
    },
    computed: {
      titulo() {
        if(this.carregando) {
          return 'Carregando...'
        }
        if(this.erro) {
          return 'Erro: Bem #' + this.codbem + ' não encontrado'
        }
        return 'Bem nº ' + this.codbem + ' - ' + this.itens[0].nombem
      },
      numFornecedoresUnicos() {
        return this.itens.map((e) => e.codfrn).filter((value, index, array) => array.indexOf(value) === index).length
      },
      minData() {
        return new Date(Math.min(...this.datas)).toLocaleString().substring(0, 10)
      },
      maxData() {
        return new Date(Math.max(...this.datas)).toLocaleString().substring(0, 10)
      },
      valores() {
        return this.itens
        .map((e) => {
          return {
            valor: e.prcuntemp,
            id: `${e.codpcddsp}-${e.numord}`
          }
        })
        .filter(a => !this.inputSelecionado(a))
        .map(e => e.valor)
        .sort((a, b) => a - b)
      },
      idxMedio() {
        return (this.valores.length)/2
      }
    },
    created() {
      this.carregando = true
      api.listarItensPorBem(this.codbem).then((resp) => {
        this.itens = resp.items;
        this.calcularEstatisticas();
        this.datas = this.itens.map((e) => this.stringParaDate(e.dtaefe));
        this.descricao = this.itens[0].dscbem?.replace(/;/g, ';\n').split("|")[0]
        this.descricao = this.descricao.substring(0, this.descricao.lastIndexOf("\n"))
      })
      .catch((erro) => {
        this.erro = erro?.mensagem ?? erro;
      })
      .finally(() => {
        this.carregando = false
      })
    },
    methods: {
      calcularEstatisticas() {
        if(this.valores.length === 0) {
          this.menor = '0,00'
          this.maior = '0,00'
          this.media = '0,00'
          this.mediana = '0,00'
        } else {
          this.menor = formataDecimal(Math.min(...this.valores))
          this.maior = formataDecimal(Math.max(...this.valores))
  
          this.valores.map((e) => {
            this.soma += parseFloat(e)
          })
          this.media = formataDecimal(this.soma / this.valores.length)
          this.soma = 0
  
          this.mediana = 0
          if(this.valores.length % 2 == 0) {
            this.mediana = formataDecimal((parseFloat(this.valores[this.idxMedio - 1]) + parseFloat(this.valores[this.idxMedio])) / 2)
          } else {
            this.mediana = formataDecimal(parseFloat(this.valores[Math.floor(this.idxMedio)]))
          }
        }
      },
      tratarAcoes({ acao, dados }) {
        switch(acao) {
          case 'Ver compra':
            this.$router.push({name:'Contratacoes:Detalhamento', params: {codpcddsp: dados.codpcddsp}, query: this.$route.query})
            break;
          default:
            break;
        }
      },
      formatarImpressao(uspCartao) {
        let $table = uspCartao.querySelector('table');

        // Seleciona e remove a primeira e última colunas do cabeçalho da tabela
        let $theadtr = $table.querySelector('thead > tr');
        $theadtr.firstChild.remove();
        $theadtr.lastChild.remove();

        // Itera pelas linhas da tabela
        [...$table.querySelectorAll('tbody > tr')].map((tbodytr) => {
          // Procura por checkbox marcado
           let $input = tbodytr.querySelector('span input')
           if (this.inputSelecionado($input)) {
              // Remove a linha inteira
             $input.parentElement.parentElement.parentElement.remove();
           } else {
             // Remove a primeira e última colunas
             tbodytr.firstChild.remove();
             tbodytr.lastChild.remove();
           }
        })
        return uspCartao
      },
      inputSelecionado(input) {
        return this.valoresSelecionados.map((el) => el.id).includes(input.id)
      },
      formataDecimal,
      formataData,
      stringParaDate
    }
  }
</script>

<style lang="scss" scoped>
.valores {
  display: grid;
  grid-template-columns: 6rem 6rem 6rem 6rem;
}

p {
  margin-bottom: 0;
}

.descricao {
  white-space: pre-wrap;
}

.dados {
  div {
    margin-bottom: 0.8rem;
  }
}

.form-check * {
  cursor: pointer;
}

strong {
  color: #1094ab;
}
</style>