import { PortalServico } from '@/utils/';
import { formataStringAcentuada } from '@/utils/Formatador';

export default class LicitacoesServico extends PortalServico {
  
  static build (options = {}) {
    options.baseURL = '/wsinfo'
    return new this(options);
  }

  async listarCampus(){
    return await this.get('api?name=campus&sortBy=codcam&desc=N');
  }
  async listarUnidades(codcam){
    if (codcam != null) {
      return await this.get(`api?name=uniddesp&length=1000&lq=codcam:${codcam}&sortBy=codunddsp&desc=N`);
    }
    return await this.get(`api?name=uniddesp&length=1000&sortBy=codunddsp&desc=N`);
  }
  async listarLicitacoes(dados, paginaAtual, token) {
    const queryLucene = this.escreveQueryLucene(dados)
    const start = (paginaAtual - 1)*10
    return await this.get(`api?name=compra&length=10&start=${start}${queryLucene}`, {
      "g-recaptcha-token": token
    });
  }
  escreveQueryLucene(dados) {
    let queryLucene = '&lq='
    for (const chave in dados) {
      let valor = dados[chave]
      switch (valor) {
        case '':
          break;
        default:
          if (queryLucene.length > 5) {
            queryLucene += ' AND '
          }
          switch (chave) {
            case "objcpr":
              valor = formataStringAcentuada(valor)
              queryLucene += `${chave}:${valor}~0.8`
              break;
            case "numleimdldsp":
              queryLucene += `${chave}:"${valor}"`
              break;
            default:
              queryLucene += `${chave}:${valor}`
              break;
          }
          break;
      }
    }
    return queryLucene
  }
  async obterLicitacao(codpcddsp, token){
    return await this.get(`api?name=compra&lq=codpcddsp:${codpcddsp}`, {
      "g-recaptcha-token": token
    })
  }
  async listarItensPorBem(codbem){
    return await this.get(`api?name=compraitem&length=10000&sortBy=prcuntemp&lq=codbem:${codbem}%20AND%20staitm:Conclu%EDdo`)
  }
  async listarItens(codpcddsp){
    return await this.get(`api?name=compraitem&length=1000&sortBy=numord&desc=N&lq=codpcddsp:${codpcddsp}`)
  }
  async listarArquivos(codpcddsp) {
    return await this.get(`api?name=compraarq&length=1000&sortBy=numseq&desc=N&lq=codpcddsp:${codpcddsp}`)
  }
  async listarLeis() {
    return await this.get(`api?name=compralei`)
  }
  async listarModalidades(numleimdldsp) {
    if (numleimdldsp != null) {
      return await this.get(`api?name=compramod&length=1000&sortBy=nommdldsp&lq=numleimdldsp:"${numleimdldsp}"`)
    }
    return await this.get(`api?name=compramod&length=1000&sortBy=nommdldsp`)
  }
  async listarSituacoes() {
    return await this.get(`api?name=comprasit&length=1000&sortBy=sitcpr`)
  }
  obterArquivo(codarq_fs) {
    return `https://uspdigital.usp.br/wsinfo/arquivo?name=compraarq&codarq_fs=${codarq_fs}`
  }
}