<!-- eslint-disable vue/no-v-html -->
<template>
  <section>
    <uspCartao
      class="licitacao-detalhes"
      :titulo="titulo"
      :fechavel="false"
    >
      <template
        v-if="carregando"
        #corpo
      >
        <uspSkeleton>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
        </uspSkeleton>
      </template>
      <template 
        v-else-if="naoEncontrado"
        #corpo
      >
        <uspAlerta variante="perigo">
          Contratação #{{ codpcddsp }} não encontrada.
        </uspAlerta>
      </template>
      <template
        v-else
        #corpo
      >
        <section class="dados-principais">
          <div>
            <span>
              <strong>Processo:</strong> {{ licitacao.numpro }}
            </span>
          </div>
          <div>
            <span>
              <strong>Telefone(s):</strong> {{ licitacao.numtel }}
            </span>
          </div>
          <div class="row-compra">
            <span>
              <strong>Compra:</strong> {{ licitacao.codpcddsp }}
            </span>
            <span>
              <strong>Modalidade:</strong> {{ licitacao.nommdldsp }} - {{ licitacao.cplnommdldsp }}
            </span>
          </div>
          <div v-if="licitacao.codoftcpr">
            <strong>Oferta de Compra BEC: </strong> {{ licitacao.codoftcpr }}
          </div>
          <div>
            <strong>Objeto(s): </strong>
            <span v-if="licitacao.objcpr">{{ licitacao.objcpr }}</span>
            <span v-else>-</span>
          </div>
          <div class="campo-obscpr">
            <strong>Observações: </strong>
            <span 
              v-if="licitacao.obscpr" 
              v-html="licitacao.obscpr" 
            />
            <span v-else>-</span>
          </div>
          <div>
            <strong>Situação: </strong> {{ licitacao.sitcpr }}
          </div>
          <div>
            <em class="small">Atualizado em: {{ licitacao.dtaalt }}</em>
          </div>
        </section>

        <div class="d-flex">
          <button
            class="btn-abas"
            :class="{ 'btn-selecionado' : verItens }"
            @click="clicaItens"
          >
            Itens
          </button>
          <button
            class="btn-abas"
            :class="{ 'btn-selecionado' : verPrazos }"
            @click="clicaPrazos"
          >
            Prazos
          </button>
          <button
            class="btn-abas"
            :class="{ 'btn-selecionado' : verArquivos }"
            @click="clicaArquivos"
          >
            Arquivos
          </button>
        </div>

        <section
          v-show="verPrazos"
          class="linha-h tabela-prazos"
        >
          <p 
            v-if="naoHaPrazos"
            class="naoHaTab"
          >
            <em class="text-muted">Não há prazos no momento.</em>
          </p>
          <div class="ml-2 mt-2">
            <p v-if="licitacao.dtafimetgamo!==null">
              <span>
                Entrega do envelope/amostra
              </span>
              <span>
                <i
                  class="far fa-calendar"
                  aria-label="data"
                /> Até {{ licitacao.dtafimetgamo }}
              </span>
              <span>
                <i
                  class="fas fa-map-marker-alt"
                  aria-label="local"
                />
                <LinkEndereco :endereco="licitacao.endetgamo" />
              </span>
            </p>
            <p v-if="licitacao.dtainiret!==null">
              <span>
                Retirada do edital
              </span>
              <span>
                <i
                  class="far fa-calendar"
                  aria-label="data"
                /> {{ licitacao.dtainiret }} a {{ licitacao.dtafimret }}
              </span>
              <span>
                <i
                  class="fas fa-map-marker-alt"
                  aria-label="local"
                />
                <LinkEndereco :endereco="licitacao.endret" />
              </span>
            </p>
            <p v-if="licitacao.dtarlzlct!==null">
              <span>
                Processamento da licitação
              </span>
              <span>
                <i
                  class="far fa-calendar"
                  aria-label="data"
                /> {{ licitacao.dtarlzlct }}
              </span>
              <span>
                <i
                  class="fas fa-map-marker-alt"
                  aria-label="local"
                />
                <LinkEndereco :endereco="licitacao.endrlz" />
              </span>
            </p>
          </div>
        </section>

        <section
          v-show="verItens"
          :class="{ 'linha-h' : arquivos.length===0 }"
        >
          <p 
            v-if="itens.length===0"
            class="naoHaTab tabela"
          >
            <em class="text-muted">Não há itens listados.</em>
          </p>
          <uspTabela
            v-else
            :campos="camposItens"
            :dados="itens"
            :acoes="acoesItens"
            :responsivo="true"
            :por-pagina="itens.length"
            @selecionar="tratarAcoes"
          />
        </section>

        <uspModal
          ref="modalDsc"
          :titulo="tituloDescricao"
          class="modal-dsc"
        >
          <template #body>
            <p class="descricao-detalhes">
              {{ descricao }}
            </p>
          </template>
        </uspModal>

        <section
          v-show="verArquivos"
          :class="{ 'linha-h' : arquivos.length===0 }"
        >
          <p
            v-if="arquivos.length===0"
            class="naoHaTab"
          >
            <em class="text-muted">Não há arquivos em anexo.</em>
          </p>
          <uspTabela
            v-else
            :campos="camposArquivos"
            :dados="arquivos"
            :acoes="acoesArquivos"
            :responsivo="true"
            @selecionar="tratarAcoes"
          />
        </section>
      </template>
    </uspCartao>
  </section>
</template>

<script>
import LicitacoesServico from "../dominio/LicitacoesServico.js";
import LinkEndereco from "../components/LinkEndereco.vue";

const api = LicitacoesServico.build({});

export default {
  name: 'LicitacoesDetalhes',
  components: {
    LinkEndereco
  },
  data() {
    return {
      quantidades: {},
      tituloDescricao: '',
      descricao: '',
      codpcddsp: this.$route.params.codpcddsp,
      licitacao: [],
      itens: [],
      arquivos: [],
      verPrazos: false,
      verItens: true,
      verArquivos: false,
      erroLicitacao: '',
      erroItens: '',
      erroArquivos: '',
      carregandoLicitacao: false,
      carregandoItens: false,
      carregandoArquivos: false,
      camposItens: [
        {
          campo: 'acoes',
          nome: 'Ações'
        },
        {
          campo: 'numord',
          numerico: true,
          ordenavel: true,
          nome: 'Item'
        },
        {
          campo: 'codbem',
          nome: 'Cód.Bem'
        },
        {
          campo: 'nombem',
          nome: 'Nome',
          ordenavel: true
        },
        {
          campo: 'qtdtotefe',
          nome: 'Quantidade',
          alinhamento: 'direita',
          formato: (valor) => {
            const indice = valor.indexOf('.') + 4
            return parseFloat(valor.substr(0, indice)).toLocaleString('pt-BR', {minimumFractionDigits: 0}) + " " + valor.substr(indice)
          }
        },
        {
          campo: 'prcuntemp',
          nome: 'Valor Unitário',
          numerico: true,
          ordenavel: true,
          alinhamento: 'direita',
          formato: (valor) => {
            return valor ? parseFloat(valor).toLocaleString('pt-BR', {minimumFractionDigits: 2}) : '-'
          }
        },
        {
          campo: 'nomfrn',
          nome: 'Fornecedor',
          ordenavel: true,
          formato: (valor) => {
            return valor || '-'
          }
        },
        {
          campo: 'dtaefe',
          nome: 'Data',
          formato: (valor) => {
            return valor || '-'
          }
        },
        {
          campo: 'staitm',
          nome: 'Situação',
          ordenavel: true,
          formato: (valor) => {
            return valor || '-' 
          }
        }
      ],
      acoesItens: [
        {
          titulo: "Ver bem",
          icone: "fas fa-external-link-alt",
          tamanho: "pq",
          variante: "primario",
          condicao: (dados) => {
            return dados.codbem !== null
          }
        },
        {
          titulo: "Descrição",
          icone: "fas fa-eye",
          tamanho: "pq",
          variante: "info",
          condicao: (dados) => {
            return dados.dscbem !== null
          }
        },
      ],
      camposArquivos: [
        {
          campo: 'acoes',
          nome: 'Ações'
        },
        {
          campo: 'numseq',
          nome: 'Nº'
        },
        {
          campo: 'tiparq',
          nome: 'Tipo'
        },
        {
          campo: 'dscarq',
          nome: 'Descrição'
        },
        {
          campo: 'nomarq',
          nome: 'Arquivo',
        }
      ],
      acoesArquivos: [
        {
          titulo: "Abrir arquivo",
          icone: "fas fa-external-link-alt",
          tamanho: "pq",
          variante: "primario",
          condicao: (dados) => {
            return dados.numseq !== null
          }
        },
      ],
    }
  },
  computed: {
    titulo() {
      if(!this.carregando){
        if(this.naoEncontrado) {
          return "Erro: código inválido."
        }
        return `Contratação n° ${ this.licitacao.numcpr }/${ this.licitacao.anocpr } - ${ this.licitacao.nomunddsp } (${ this.licitacao.sglunddsp }), Campus ${ this.licitacao.nomcam }`
      }
      return "Carregando..."
    },
    carregando() {
      return !(!this.carregandoLicitacao && !this.carregandoItens && !this.carregandoArquivos)
    },
    naoEncontrado() {
      if(!this.carregando) {
        return (this.licitacao === undefined || this.licitacao.length===0)
      }
      return false
    },
    naoHaPrazos() {
      return (this.licitacao.dtafimetgamo===null & this.licitacao.dtainiret===null & this.licitacao.dtarlzlct===null)
    }
  },
  created() {
    this.carregandoLicitacao = true
    this.obter()
  },
  methods: {
    async obter() {
      await this.$recaptchaLoaded()
      try {
        const token = await this.$recaptcha('licitacao')
        api.obterLicitacao(this.codpcddsp, token).then((resp) => {
          this.licitacao = resp.items[0]
          this.carregarItensArquivos()
        })
        .catch((erro) => {
          this.erroLicitacao = erro?.mensagem ?? erro;
        })
        .finally(() => this.carregandoLicitacao = false)
      } catch (error) {
        console.log(error)
      }
    },
    carregarItensArquivos() {
      this.carregandoItens = true
      api.listarItens(this.codpcddsp).then((resp) => {
          this.itens = resp.items;
          this.itens.forEach((e, index) => {
            this.itens[index].qtdtotefe =  resp.items[index].qtdtotefe + resp.items[index].undfrn
          }) 
        })
        .catch((erro) => {
          this.erroItens = erro?.mensagem ?? erro;
        })
        .finally(() => {
          this.carregandoItens = false
        })
      this.carregandoArquivos = true
      api.listarArquivos(this.codpcddsp).then((resp) => {
          this.arquivos = resp.items
        })
        .catch((erro) => {
          this.erroArquivos = erro?.mensagem ?? erro;
        })
        .finally(() => this.carregandoArquivos = false)
    },
    clicaPrazos() {
      this.verPrazos = true;
      this.verItens = false;
      this.verArquivos = false;
    },
    clicaItens() {
      this.verPrazos = false;
      this.verItens = true;
      this.verArquivos = false;
    },
    clicaArquivos() {
      this.verPrazos = false;
      this.verItens = false;
      this.verArquivos = true;
    },
    linkArquivo(item) {
      return api.obterArquivo(item.codarq_fs)
    },
    verMais(item) {
      this.tituloDescricao = item.numord + " - " + item.nombem
      this.descricao = item.dscbem.replace(/; /g, ';\n');
      this.$refs['modalDsc'].openModal();
    },
    tratarAcoes({ acao, dados }) {
      switch (acao) {
        case 'Descrição':
          this.verMais(dados)
          break;
        case 'Ver bem':
          this.$router.push({name:'Contratacoes:ItemBem', params: {codbem: dados.codbem}, query: this.$route.query})
          break;
        case 'Abrir arquivo':
          window.open(this.linkArquivo(dados), '_blank')
          break;
        default:
          break;
      }
    }
  }

}
</script>

<style lang="scss">

.modal-dsc {
  .modal-dialog {
    max-width: 60vw;
  }
}
@media (max-width: 770px) {
.modal-dsc {
  .modal-dialog {
    max-width: 90vw;
  }
}
}
</style>


<style lang="scss" scoped>
.descricao-detalhes, .campo-obscpr {
  white-space: pre-wrap;
}

i {
  margin: 0 0.8rem;
}

.dados-principais {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  div {
    margin-bottom: 0.8rem;
    span:not(:last-child) {
      margin-bottom: 0.8rem;
      margin-right: 2rem;
    }
  }
}

.tabela-prazos{
  div:first-child {
    padding: 0.8rem;
  }
  p {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    span:first-child {
      font-weight: bold;
    }
    span {
      margin-bottom: 0.5rem;
    }
  }
}

.naoHaTab {
  margin-top: 1rem;
  margin-left: 1rem
}

.btn-abas{
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding: 0.5rem 1rem;
  font-weight: bold;
  border-bottom: none;
}

@import '@/componentes/estilos/_temas.scss';
@each $tema in $temas {
  $elemento: map-get($tema, "elemento");
  #{$elemento} {
    .btn-abas{
      border: 1px solid map-get($tema, "usp-elemento-cor-de-fundo");
      color: map-get($tema, "usp-elemento-cor-do-texto");
      background-color: map-get($tema, "usp-subelemento-cor-de-fundo-ativo");
      border-bottom: none;
    }
    .btn-selecionado {
      background-color: map-get($tema, "usp-elemento-cor-de-fundo");
      color: map-get($tema, "usp-item-selecionado");
      border: 1px solid #dee2e6;
    }
    strong {
      color: $usp-cores-azul-primario;
    } 
    .linha-h {
      border-top: 1px solid #dee2e6;
    }
  }
}
</style>